import create from 'zustand'
import { devtools } from 'zustand/middleware'

const useStore = create(
	devtools((set, get) => ({
		selectedModalVenue: null,
		setSelectedVenueModal: venue => set({ selectedModalVenue: venue }),

		modal: '',
		setModal: modal => set({ modal }),
		closeModal: () => set({ modal: '' }),
	}))
)

export default useStore
