import '../styles/Main.css'
function Main({ assetsPath }) {
	return (
		<main className="main">
			<img
				className="background-main"
				alt="background-main"
				src={`${assetsPath}assets/images/main-image.png`}
			/>

			<div className="buttons-container buttons-container-main">
				<a href="https://shows.fanflex.com/venues">
					<img
						className="button-artist-main no-hover"
						alt="button-artist"
						src={`${assetsPath}assets/images/artist-button.png`}
					/>
					<img
						className="button-artist-main button-hover"
						alt="button-artist"
						src={`${assetsPath}assets/images/button-artist-hover.png`}
					/>
				</a>
				<a href="https://shows.fanflex.com/venues">
					<img
						className="button-venue-main no-hover"
						alt="button-venue"
						src={`${assetsPath}assets/images/venue-button.png`}
					/>
					<img
						className="button-venue-main button-hover"
						alt="button-venue"
						src={`${assetsPath}assets/images/button-venue-hover.png`}
					/>
				</a>
			</div>

			<div className="sign-up-container">
				<p className="sign-up-text">
					Sign up now and get started booking today
				</p>
			</div>
		</main>
	)
}

export default Main
