import { useEffect, useState } from 'react'
import './App.css'
import Footer from './components/Footer'
import Header from './components/Header'
import Main from './components/Main'

function App() {
	const [assetsPath, setAssetsPath] = useState(window.location.hostname === 'localhost' ? '/' : "")

  useEffect(() => {
	if(window.location.hostname === 'localhost') return;
	// Pause execution until window.reactInit.scriptData is set
	fetch("/wp-json/monarchy-child/v1/get-assets-url/").then((response) => {
		const parsedString = response.json()
		parsedString.then((data) => {
			console.log(data)
			setAssetsPath(data)
		})
	}).catch((error) => {
		console.log(error)
	})
  }, [])

  useEffect(() => {
	console.log(assetsPath)
  }, [assetsPath])

  if(!assetsPath) return (<div>Loading...</div>);

	return (
		<div className="App">
			<Header assetsPath={assetsPath} />
			<Main assetsPath={assetsPath} />
			<Footer assetsPath={assetsPath} />
		</div>
	)
}

export default App
