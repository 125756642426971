import "../styles/Footer.css";
import Item from "./Item";

import { BsChevronRight } from "react-icons/bs";

const venues = [
  {
    id: "001-daddydiamonds",
    name: "Daddy Diamonds",
    place: "1553 N. Cahuenga Blvd - Los Angeles CA",
    description:
      "Daddy Diamonds is a NEW Electro-Mechanical Champagne Lounge located in the Heart of Hollywood.",
    link: "https://shows.fanflex.com/venues/96IKxqx49AeivgzqBNWR",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fanflex-82950.appspot.com/o/venues%2F96IKxqx49AeivgzqBNWR%2FcarouselImages%2F89b15cdb-7c03-46da-ba17-40f76912c411?alt=media&token=832d8505-b55f-4962-8a51-e318edb0ea76",
  },
  {
    id: "002-hotelziggy",
    name: "Hotel Ziggy",
    place: "8462 Sunset Blvd - West Hollywood CA",
    link: "https://shows.fanflex.com/venues/AMRaFWUnjmqOGh5YZVlc",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fanflex-82950.appspot.com/o/venues%2FAMRaFWUnjmqOGh5YZVlc%2FcarouselImages%2F52eec3eb-b472-4917-a2a3-5b5b9e5a598c?alt=media&token=7db79d03-bc5f-4b32-b878-74b8ec167619",
  },
  {
    id: "003-joesbarandgrill",
    name: "Joe`s Bar and Grill",
    place: "124 W Commonwealth Ave. - Fullerton CA",
    link: "https://shows.fanflex.com/venues/cM9RyKL8lChmWji9uVK6",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fanflex-82950.appspot.com/o/venues%2FcM9RyKL8lChmWji9uVK6%2FcarouselImages%2F08d6bac8-3484-4912-8110-c723b9b43732?alt=media&token=0c2fabb6-7b5b-486f-9c55-f0bcc0153f3a",
  },
  {
    id: "004-thekibitzroom",
    name: "The Kibitz Room",
    place: "419 N Fairfax Ave. - Los Angeles CA",
    link: "https://shows.fanflex.com/venues/mJpBQvvaSl89sEUGMsfz",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fanflex-82950.appspot.com/o/venues%2FmJpBQvvaSl89sEUGMsfz%2FcarouselImages%2F313c50df-c06c-4ba7-a7d2-c3011bde96ba?alt=media&token=e51491f3-709c-4739-bacb-411237c45cf8",
  },
  {
    id: "005-navajolivebar",
    name: "Navajo Live Bar",
    place: "8515 Navajo Rd - San Diego CA",
    link: "https://shows.fanflex.com/venues/zrcwkUh9VPn1XjKhiD0G",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fanflex-82950.appspot.com/o/venues%2FzrcwkUh9VPn1XjKhiD0G%2FcarouselImages%2Ff221c092-a89f-4ebc-a43f-b02690162cb0?alt=media&token=0f27fd87-3f3a-4023-aad6-4a73c6b98b6b",
  },
  {
    id: "006-nolaartbar",
    name: "Nola Art Bar",
    place: "2128 St. Claude Ave - New Orleans LA",
    link: "https://shows.fanflex.com/venues/P7bVcsgNCNaDHJdbbdQY",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fanflex-82950.appspot.com/o/venues%2FP7bVcsgNCNaDHJdbbdQY%2FcarouselImages%2Fba78faf7-c931-4c87-8db6-3ce6df97035c?alt=media&token=18c033e0-ab96-42ba-8838-3a605864acbf",
  },
  {
    id: "007-carrerestaurantandbar",
    name: "Carré Restaurant and Bar",
    place: "239 Decatur St. - New Orleans LA",
    link: "https://shows.fanflex.com/venues/7lALVH81S7QV8oAT9cQ0",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fanflex-82950.appspot.com/o/venues%2F7lALVH81S7QV8oAT9cQ0%2FcarouselImages%2F6366555e-bbe8-4058-8eee-fe34154a2929?alt=media&token=4e81a3be-92c4-45c2-9fa9-2c195da96061",
  },
  {
    id: "008-upperquarter",
    name: "Upper Quarter",
    place: "1000 Bienville St - New Orleans LA",
    link: "https://shows.fanflex.com/venues/fnN96ELUekvxMORnyS5c",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/fanflex-82950.appspot.com/o/venues%2FfnN96ELUekvxMORnyS5c%2FcarouselImages%2Fa0c1cf63-fe97-4a78-a63a-0dd064ea80cb?alt=media&token=7d36178a-dc7e-4e3d-86e2-4941caec24cb",
  },
];

function Footer({ assetsPath }) {
  return (
    <footer className="footer" style={{ backgroundImage: `url("${assetsPath}/assets/images/featured-background-2.png")` }}>
      {/* GRID SECTION*/}
      <section className="section__style container-featured">
        <div>
          <h3 className="featured-title">featured</h3>
          <ul className="list__section">
            {venues.map((item) => (
              <Item item={item} key={item.id} />
            ))}
          </ul>

          {/* SEARCH MORE BUTTON */}
          <div className="search-more-btn-container">
            <a href="https://shows.fanflex.com/venues">
              <img
                className="search-more-button no-hover"
                alt="button-venue"
                src={`${assetsPath}assets/images/search-more-button.png`}
              />
              <img
                className="search-more-button button-hover"
                alt="button-venue"
                src={`${assetsPath}assets/images/search-more-button-hover.png`}
              />
            </a>
          </div>
        </div>
      </section>

      {/* LINKS SECTION */}
      <section className="symbol-link-section">
        <div className="block-social">
          <div className="fanflex-symbol-footer">
            <img
              alt="fanflex symbol"
              src={`${assetsPath}assets/images/fanflex-symbol-footer.png`}
            />
            <div className="social-links">
              <a href="https://www.facebook.com/fanflexapp/">
                <img
                  className="fanflex-social"
                  alt="FB symbol"
                  src={`${assetsPath}assets/icons/facebook-icon.svg`}
                />
              </a>
              <a href="https://twitter.com/fanflexapp">
                <img
                  className="fanflex-social"
                  alt="twitter symbol"
                  src={`${assetsPath}assets/icons/twitter-icon.svg`}
                />
              </a>
              <a href="https://www.linkedin.com/company/fanflex/">
                <img
                  className="fanflex-social"
                  alt="linkedin symbol"
                  src={`${assetsPath}assets/icons/linkedin-icon.svg`}
                />
              </a>
              <a href="https://www.youtube.com/channel/UCmsm7N8HoF3R_TSfiYCMdMQ">
                <img
                  className="fanflex-social"
                  alt="youtube symbol"
                  src={`${assetsPath}assets/icons/youtube-icon.svg`}
                />
              </a>
              <a href="http://fanflex.com/contact/">
                <img
                  className="fanflex-social"
                  alt="call symbol"
                  src={`${assetsPath}assets/icons/call-icon.svg`}
                />
              </a>
            </div>
          </div>
        </div>

        <div className="links-part">
          <div className="block-quick">
            <h4>QUICK</h4>
            <div className="footer-links">
              <a href="/how-it-works/">How It Works</a>
              <a href="/about-us/">About Us</a>
              <a href="/careers/">Careers</a>
              <a href="/partners/">Artist Resources</a>
              <a href="/blog/">Blog</a>
              <a href="/legal/">Legal</a>
              <a href="/contact/">Contact Us</a>
            </div>
          </div>
          <div className="block-press">
            <h4>PRESS</h4>
            <div className="footer-links">
              <ul>
                <li>
                  <BsChevronRight className="chevron" />
                  <a href="/interview-jess-fuller/">
                    Interview: Jess Fuller
                  </a>
                </li>
                <li>
                  <BsChevronRight className="chevron" />
                  <a href="/bryson-nobles-on-sade-kanye-coldplay-and-the-world-of-sync-music-licensing/">
                    Bryson Nobles on Sade, Kanye, Coldplay, and the World of
                    Sync Music Licensing
                  </a>
                </li>
                <li>
                  <BsChevronRight className="chevron" />
                  <a href="/press/">View all press</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="monarchy-logo">
        <img src={`${assetsPath}assets/icons/monarchy-logo.png`} alt="monarchy logo" />
      </section>
    </footer>
  );
}

export default Footer;
