import useStore from "../store";

const placeholder = "../public/assets/images/placeholder.jpg";

function Item({ item }) {
  const setModal = useStore((store) => store.setModal);
  const setSelectedMovieModal = useStore(
    (store) => store.setSelectedMovieModal
  );
  const name = item.name;
  return (
    <li>
      <a href={item.link}>
        <article
          className="movie__item container"
          onClick={() => {
            setModal("movie");
            setSelectedMovieModal(item);
          }}>
          <img
            className="image"
            src={item.imageUrl ? item.imageUrl : placeholder}
            alt={name}
          />
          <div className="middle">
            <div className="text">
              <h2 className="venue_name">{item.name || "name not found"}</h2>
              <h2 className="venue_address">
                {item.place || "address not found"}
              </h2>
              <button>book now</button>
            </div>
          </div>
        </article>
      </a>
    </li>
  );
}

export default Item;
